.DropdownMenu {
	display: grid;
	align-items: center;
	color: var(--primary-color);
	position: absolute;
	right: 24px;
	top: 70px;
	background-color: white;
	z-index: 9;
	border: 1px solid black;
	box-shadow: 0px 2px 3px 0px #00000017;
	border-radius: 12px;
	border: none;
	padding: 4px;
	font-weight: 400;
	font-size: 14px;

	.DropdownMenuItem {
		display: grid;
		align-items: center;
		padding: 8px 12px;
		border-radius: 12px;
		cursor: pointer;

		&--hasIcon {
			grid-template-columns: 24px 1fr;
			gap: 6px;
			white-space: nowrap;
		}

		&--danger {
			color: #FF2525;
		}

		&:hover {
			background: #FCF3F7;
			color: var(--highlight-color)
		}

		input {
			display: none;
		}
	}
}