.CurrentLanguageButton {
	display: inline-grid;
	grid-auto-flow: column;
	border-radius: var(--form-button-border-radius);
	border: var(--form-element-border);
	padding: 18px 24px;
	gap: 10px;
	align-items: center;
	text-decoration: none !important;
	color: var(--primary-color);
	font-size: 16px;
	font-weight: 400;
	line-height: normal;
	white-space: nowrap;
	cursor: pointer;

	&:hover {
		text-decoration: none !important;
		background-color: #fff;
		border: 1px solid #fff;
		color: var(--highlight-color);
	}

	&_Flag {
		display: block;
		width: 27px;
		height: 20px;
	}
}
