@import '../../../ruya-shared/shared/assets/mixins';

.Timeline {
	position: relative;
	width: 100%;

	&_Container {}

	&_Area {
		display: grid;

		@include minTablet {
			grid-auto-flow: column;
			grid-template-columns: 280px 1fr;
			gap: 32px;
		}

		&_Menu {}

		&_Content {}

	}
}