.SearchBox {
	display: grid;
	grid-template-columns: 1fr 18px;
	box-shadow: 0px 2px 3px 0px #00000017;
	border-radius: 8px;
	background-color: var(--input-background);
	height: 44px;
	padding: 9px 18px;

	&:focus-within {
		outline: var(--input-focus-border-size) solid var(--input-focus-border-color);
		outline-offset: var(--input-focus-border-size);
		border: none;
	}

	&_Input {
		width: 100%;
		font-size: 16px;
		font-weight: 400;
		border: none;
		height: 26px;
		color: var(--primary-color);
		background-color: var(--input-background);

		&::placeholder {
			color: var(--input-placeholder-color);
			opacity: 1;
		}

		&:focus {
			outline: none;
			border: none;
		}
	}
}