@import '../../../ruya-shared/shared/assets/mixins';

.DiaryView {

	&_Content {

		&_TopBar {
			display: grid;
			grid-auto-flow: column;
			justify-content: space-between;
			width: 100%;
			margin-bottom: 48px;

			div {
				display: grid;
				grid-auto-flow: column;
				gap: 12px;
			}
		}

		&_Title {
			margin-bottom: 48px;
		}

		&_DiaryInfo {
			display: grid;
			grid-auto-flow: column;
			gap: 15px;
			align-items: center;
			font-size: 14px;
			font-weight: 400;
			justify-content: start;
			margin-bottom: 48px;

			&_Date {
				display: grid;
				grid-auto-flow: column;
				align-items: center;
				gap: 8px;

				.MaterialSymbol {
					font-size: 18px;
					color: #0F3652;
				}
			}

			&_Lucid {
				display: grid;
				grid-auto-flow: column;
				align-items: center;
				gap: 8px;

				.MaterialSymbol {
					font-size: 18px;
					color: #0F3652;
				} 
			}

			&_Separator {
				display: block;
				width: 6px;
				height: 6px;
				background-color: #CDCDD6;
				border-radius: 50%;
			}
		}

		&_Diary {
			font-weight: 400;
			font-size: 16px;
			line-height: 25.6px;
			margin-bottom: 24px;
			white-space: pre-wrap;
		}
	}
}