@import '../../../ruya-shared/shared/assets/mixins';

.TimelineWelcome {
	padding: 32px 0;

	&_Header {
		margin: 0;
	}

	&_Text {
		text-align: center;
		font-size: 18px;
		padding: 32px 0;
		margin: 0;

		span {
			@include minTablet {
				display: block;
			}
		}
	}

	&_Add {
		display: grid;
		justify-content: center;
		justify-items: center;
		gap: 12px;
	
		@include minTablet {
			grid-auto-flow: column;
		}
	
		.Button {
			width: min-content;
		}
	}
}