@import '../../../assets/variables.scss';

.Badge {
	border-radius: 6px;
	font-size: 12px;
	font-weight: 400;
	white-space: nowrap;
	width: min-content;
	display: inline-block;
	padding: 6px 8px;

	&--hasIcon {
		display: inline-grid;
		grid-auto-flow: column;
		gap: 8px;
		align-items: center;

		.MaterialSymbol {
			font-size: 18px;
		}
	}

	// Color
	&--info {
		background: var(--info-color);
		color: #ffffff;
	}

	&--success {
		background: var(--success-color);
		color: #ffffff;
	}

	&--warning {
		background: var(--warning-color);
		color: #ffffff;
	}

	&--error {
		background: var(--error-color);
		color: #ffffff;
	}

	&--light {
		background: #FFFFFF;
		color: var(--color-primary);

		border: 1px #CDCDD6 solid;

		// Remove
		text-transform: uppercase;
	}

	&--dark {
		background: #000000;
		color: #ffffff;
	}

	&--dream {
		background: var(--dream-badge-bg);
		color: #0F3652;

		.MaterialSymbol {
			color: var(--dream-badge-icon)
		}
	}

	&--diary {
		background: var(--diary-badge-bg);
		color: #0F3652;

		.MaterialSymbol {
			color: var(--diary-badge-icon)
		}
	}

	&--event {
		background-color: var(--event-badge-bg);
		color: var(--color-primary);

		.MaterialSymbol {
			color: var(--event-badge-icon);
		}
	}
}