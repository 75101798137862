@import '../../../assets/mixins';

// Date Selector Container
.DateSelector {
	display: grid;
	position: relative;

	// React date picker overrides
	.react-datepicker {
		font-family: var(--default-font) !important;
		font-weight: 400 !important;
		border: 1px solid #fff;
		border-radius: 13px;
		box-shadow: var(--input-box-shadow);

		&__current-month {
			color: var(--primary-color) !important;
			font-weight: 400 !important;
			font-size: 14px !important;

			&--hasMonthDropdown,
			&--hasYearDropdown {
				display: none;
			}
		}

		&__header__dropdown {
			display: grid;
			grid-template-columns: min-content min-content;
			justify-content: center;
			width: min-content;
			margin: 0 auto;
			gap: 10px;
		}

		&__month-dropdown-container,
		&__year-dropdown-container {
			margin: 0;
		}

		&__month-select,
		&__year-select {
			border: 0;

			&:focus {
				outline: var(--input-focus-border-size) solid var(--highlight-color);
				outline-offset: var(--input-focus-border-size);
				border: none;
				border-radius: 2px;
			}
		}

		&-time__header {
			color: var(--primary-color) !important;
			font-weight: 400 !important;
			font-size: 14px !important;
		}

		// Z-Index bug fix
		&-popper {
			z-index: 25;
		}

		// Alignment bug fix
		&__triangle {
			transform: translate(10em, 0) !important;
		}

		&__day-name,
		&__time-name {
			color: #b6b6b6;
		}

		&__header {
			background-color: var(--input-background) !important;
		}

		&__day--selected,
		&__day--keyboard-selected,
		&__time-list-item--selected {
			background-color: var(--highlight-color) !important;
			color: #fff;
		}

		&__navigation-icon::before {
			border-color: var(--highlight-color) !important;
			border-width: 2px 2px 0 0;
		}

		&__triangle {
			transform: rotate(180deg) translateY(-1px) !important;
			fill: #fff;
			color: #fff;
			stroke: #fff;
		}
	}

	&_Label {
		display: grid;
		grid-auto-flow: column;
		font-size: 18px;
		margin-bottom: 10px;
		gap: 6px;
		justify-items: start;
		justify-content: start;
		align-items: center;

		.MaterialSymbol {
			color: var(--input-icon-color);
			font-size: 18px;
			line-height: 18px;
		}

		label {
			color: var(--primary-color);
			white-space: nowrap;
			font-size: 16px;
			font-weight: 400;
		}

		&_Help {
			cursor: help;
			justify-self: end;

			&:hover {
				color: var(--highlight-color);
			}
		}
	}

	&_Wrapper {
		display: grid;
		position: relative;
	}

	// Clear button
	&_ClearButton {
		display: grid;
		align-items: center;
		cursor: pointer;
		background-color: transparent;
		border: 0;
		outline: 0;
		padding: 0;
		position: absolute;
		top: 0;
		right: 55px;
		height: 100%;

		&::after {
			content: '\e5cd';
			font-family: var(--default-Icon-font);
			background-color: transparent;
			font-weight: 900;
			color: #c1c1c1;
			font-size: 18px;
			padding: 0;
		}
	}

	// Invalid Message Feedback
	&_InvalidMessage {
		display: grid;
		grid-template-columns: min-content auto;
		color: var(--error-color);
		margin: 8px 0 0 0;
		font-weight: 400;
		align-items: center;
		gap: 8px;
		font-size: var(--input-error-font-size);

		.MaterialSymbol {
			font-size: var(--input-error-font-size);
		}
	}

	// Warning state
	&.DateSelector--warning {
		label {
			color: var(--warning-color);
		}

		&::before {
			color: var(--warning-color) !important;
		}

		input {
			border-color: var(--warning-color);
			color: var(--warning-color);
		}
	}

	// Invalid state
	&.DateSelector--invalid {
		label {
			color: var(--error-color);
		}

		&::before {
			color: var(--error-color) !important;
		}

		input {
			border-color: var(--error-color);
			color: var(--error-color);
		}
	}
}