.ToggleButton {
	align-items: center;
	cursor: pointer;
	display: inline-grid;
	font-size: var(--button-sm-font-size);
	border-width: var(--button-border-width);
	border-style: solid;
	border-radius: var(--button-sm-border-radius);
	height: var(--button-sm-height);
	padding: var(--button-sm-padding);
	color: var(--button-secondary-color);
	background-color: var(--button-secondary-bg);
	border-color: var(--button-secondary-border-color);

	.MaterialSymbol {
		color: var(--button-secondary-color);
		font-size: var(--button-sm-icon-size);
	}

	&--checked {
		.MaterialSymbol {
			color: var(--highlight-color);
		}
	}

	&:hover {		
		.MaterialSymbol {
			color: var(--highlight-color);
		}
	}

	input {
		display: none;
	}
}