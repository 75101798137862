@import '../../../ruya-shared/shared/assets/mixins';

.Interpreter {
	display: grid;
	grid-template-columns: 36px 1fr;
	gap: 24px;
	align-content: start;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0px 2px 3px 0px #00000017;
	padding: 24px;

	@include minTablet {
		grid-template-columns: 36px 1fr 60px;
	}

	// Icon
	&_Icon {
		display: grid;
		background-color: #FCE0EA;
		border-radius: 8px;
		width: 36px;
		height: 36px;
		align-items: center;
		text-align: center;
	}

	&_Explanation {

		&_Title {
			display: grid;
			
			gap: 10px;

			@include minTablet {
				grid-template-columns: max-content 1fr;
			}

			.P {
				margin: 0;
			}
		}

		&_Details {
			margin-bottom: 12px;

			.P span {
				display: block;
				margin-bottom: 6px;
				font-weight: 600;
			}
		}

		// Buttons
		&_More {
			color: #FF3974;
			text-decoration: underline;
			font-size: 14px;
			cursor: pointer;
		}

		&_Less {
			color: #FF3974;
			text-decoration: underline;
			font-size: 14px;
			cursor: pointer;
		}
	}

	&_Button {
		grid-column: span 2;

		@include minTablet {
			grid-column: unset;
		}

		.Button {
			width: calc(100% - 60px);
			margin-left: 60px;

			@include minTablet {
				width: unset;
				margin-left: unset;
			}
		}
	}
}