@import '../../../ruya-shared/shared/assets/mixins';

.TimelineDate {
	display: grid;
	grid-auto-flow: column;
	gap: 8px;
	align-items: center;
	justify-items: center;
	
	border: 1px solid #E4E0E6;
	border-radius: 12px;
	padding: 10px;

	@include minTablet {
		grid-auto-flow: row;
		width: 53px;
		gap: 4px;
		padding: 16px 0;
	}

	&_Day {
		font-size: 16px;
		font-weight: 600;

		@include minTablet {
			color: #0F3652;
		}
	}

	&_Month {
		font-size: 12px;
		font-weight: 400;
		color: #8594A4;
	}

	&_Year {
		font-size: 12px;
		font-weight: 400;
		color: #a7b6c5;

		@include minTablet {
			font-weight: 400;
			font-size: 11px;
			color: #8594A4;
		}
	}
}