@import '../../../ruya-shared/shared/assets/mixins';

.NavigationMobile {
	display: block;
	position: fixed;
	width: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(15, 54, 82, 0.5);
	margin: 0;
	z-index: 99;

	@include minDesktop {
		display: none;
	}

	&_Top {
		display: grid;
		grid-auto-flow: column;
		justify-content: space-between;
		background: var(--secondary-bg);
		border-bottom: var(--navigation-mobile-bottom-border);
		padding: 18px;
	}

	&_MobileButton {
		display: grid;
		grid-auto-flow: column;
		border-radius: 50%;
		border: var(--form-element-border);
		align-items: center;
		text-align: center;
		justify-self: end;
		width: 36px;
		height: 36px;
		cursor: pointer;

		@include minDesktop {
			display: none;
		}

		svg {
			fill: var(--primary-color);
			// TODO: Fix this
			font-size: var(--default-mobile-font-size);
		}

		&:hover {
			background-color: #fff;
			border: 1px solid #fff;

			svg {
				fill: var(--highlight-color);
			}
		}
	}

	&_Menu {
		list-style-type: none;
		padding: 18px;
		margin: 0;
		background-color: #fff;

		&_Separator {
			height: 1px;
			background-color: var(--navigation-mobile-separator-color);
			margin: 12px 0;
		}

		&_Item {
			display: grid;
			align-items: center;
			text-align: start;
			height: 48px;
			margin-bottom: 2px;

			a {
				display: grid;
				width: 100%;
				padding: 7px 24px;
				grid-template-columns: 24px 1fr;
				gap: 10px;
				text-decoration: none;
				border-radius: 12px;

				span {
					color: var(--primary-color);
					font-size: 16px;
					line-height: 24px;
				}

				.MaterialSymbol {
					width: 24px;
					height: 24px;
					line-height: 24px;
					text-align: center;
					font-size: 24px;
				}

				&:hover,
				&:focus {
					background-color: var(--secondary-bg);
				}
			}

			&--active {
				background-color: var(--secondary-bg);
			}
		}
	}
}
