.RadioBox {
	display: inline-block;

	label {
		display: grid;
		grid-template-columns: auto 1fr;
		align-items: center;
		gap: 12px;

		.MaterialSymbol {
			font-size: 16px;
			color: #0F3652
		}

		&:hover {
			color: #FF3974;

			.MaterialSymbol {
				color: #FF3974;
			}
		}
	}

	&_Label {
		margin: 0;
	}

	&_Input {
		display: none;
	}

	&_Radio_Checked {
		display: none;
	}

	input[type='radio']:checked~.RadioBox_Radio_Unchecked {
		display: none;
	}

	input[type='radio']:checked~.RadioBox_Radio_Checked {
		display: unset;
		color:#FF3974;
	}

	input[disabled]~.RadioBox_Label,
	input[disabled]~.RadioBox_Radio_Unchecked,
	input[disabled]~.RadioBox_Radio_Checked {
		opacity: 0.5;
	}
}