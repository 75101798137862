.PublicLanguages {

	&_Wrapper {
		display: grid;
		grid-auto-flow: row;
		grid-template-rows: auto auto 1fr;

		.H1 {
			margin: 0;
			margin-bottom: 24px;
		}
	}

	&_Desc {
		text-align: center;
	}
}
