@import '../../../ruya-shared/shared/assets/mixins';

// Timeline Dream Box
.TimelineDream {
	position: relative;
	display: grid;
	background-color: #FDF9FB;
	box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.09);
	border-radius: 8px;
	padding: 24px;
	gap: 12px;

	.Loading {
		display: none;
	}

	&_Section {
		display: grid;
		gap: 12px;

		.Label {
			color: #8594A4;
		}

		&_List {
			display: flex;
			flex-wrap: wrap;
			gap: 12px;
			justify-content: flex-start;
			width: 100%;

			&_MoreButton {
				display: grid;
				grid-auto-flow: column;
				gap: 5px;
			}

		}

	}

	// Dream Header
	&_Header {
		display: grid;
		grid-auto-flow: column;
		gap: 12px;
		margin-bottom: 24px;

		&_Title {
			display: grid;
			grid-auto-flow: column;
			color: #6f8495;
			justify-content: start;
			align-items: center;
			font-size: 14px;
			font-weight: 400;
			gap: 6px;
		}

		&_Actions {
			position: relative;
			display: grid;
			grid-auto-flow: column;
			justify-content: end;
			gap: 12px;
		}
	}

	// Dream Details
	&_Details {
		overflow: hidden;
		position: relative;
		max-height: 180px;
		min-height: 50px;

		&_Content {
			font-size: 16px;
			font-weight: 400;
			line-height: 25.6px;
		}

		&_Gradient {
			position: absolute;
			bottom: 0;
			height: 25px;
			width: 100%;
			background: linear-gradient(0deg, rgba(253, 249, 251, 1) 0%, rgba(253, 249, 251, 0.3) 90%);
		}
	}

	// Dream Interpretations
	&_Interpretation {
		display: grid;
		grid-template-columns: 24px 1fr;
		gap: 8px;
		cursor: pointer;

		&_Name {
			font-weight: 400;
			color: #0F3652;
			font-size: 16px;
		}

		.MaterialSymbol {
			background: #FCE0EA;
			color: #0F3652;
			width: 24px;
			height: 24px;
			padding: 4px;
			border-radius: 5.33px;
			font-size: 16px;
		}

		&:hover {
			.TimelineDream_Interpretation_Name {
				color: var(--highlight-color);
			}

			.MaterialSymbol {
				background-color: var(--highlight-color);
				color: #fff;
			}
		}
	}

	&_More {
		display: grid;
		grid-auto-flow: column;
		font-weight: 400;
		color: #0F3652;
		font-size: 16px;
		gap: 8px;
		cursor: pointer;

		&_Box {
			margin-top: 0;
			width: 24px;
			height: 24px;
			background: #FF3974;
			color:white;
			text-align: center;
			border-radius: 4px;
		}
		
		&_Text {
			margin-top: 0;
			color: #FF3974;

		}

	}

	// Dream Actions
	&_Actions {
		display: grid;
		grid-auto-flow: column;
		gap: 12px;
	}

	// Dream Menu
	&_Menu {
		position: absolute;
		right: 24px;
		top: 70px;
		background-color: white;
		z-index: 9;
		border: 1px solid black;
		width: 105px;
		height: 111px;
		box-shadow: 0px 2px 3px 0px #00000017;
		border-radius: 12px;
		border: none;
	}

	// Deleted item animation
	&--deleted {
		opacity: 0;
		transition: opacity 0.5s;
	}
}