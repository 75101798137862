@import '../../../ruya-shared/shared/assets/mixins';

.TimelineMenu {
	display: grid;
	grid-auto-flow: row;
	gap: 24px;

	&_Buttons {
		display: grid;
		grid-auto-flow: row;
		gap: 12px;

		&_Add {
			display: grid;
			grid-auto-flow: column;
			justify-content: start;
			gap: 12px;
		}

		.Button {
			@include minTablet {
				width: min-content;
			}
		}
	}

	&_Filters {

		&_Form {
			display: grid;
			gap: 24px;
			padding-bottom: 32px;
		}

		&_Item {
			@include minTablet {
				display: grid;
				grid-auto-flow: row;
				justify-content: start;
				align-items: center;
				gap: 12px;
			}

			.RadioBox {
				display: inline-block;
				margin-left: 12px;

				label {
					gap: 4px;
					align-items: center;
				}
			}

			&_Title {
				display: block;
				font-weight: 400;
				color: #8594A4;
				font-size: 12px;
				height: 23px;

				@include minTablet {
					display: unset;
				}
			}
		}

		&_Buttons {
			display: grid;
			grid-auto-flow: column;
			justify-content: start;
			gap: 12px;
		}

		&--closed {
			opacity: 0;
			height: 0;
			transition: all 0.5s ease-in-out;
		}

		&--open {
			opacity: 1;
			transition: all 0.5s ease-in-out;
		}
	}
}