@import '../../../assets/mixins';

.H3 {
	font-weight: 400;
	font-size: 22px;
	line-height: 140%;
	color: var(--primary-color);

	&--centered {
		text-align: center;
	}
}