.DreamQA {
	height: 100%;

	&_InnerContainer {
		position: relative;
		height: 100%;
	}

	&_InnerScroll {
		position:absolute;
		padding: 10px;
		top:0;
		bottom:0;
		width: 100%;
		overflow-y:auto;
		max-height: calc(100% - 69px);
	}
	
	&_Chat {
		display: grid;
		align-content: end;
		
		&_Item {
			display: grid;
			width: auto;
			max-width: 700px;
			font-size: 16px;
			margin-bottom: 32px;
			gap:12px;

			.MaterialSymbol {
				display: grid;
				width: 36px;
				height: 36px;
				border-radius: 8px;
				font-size: 24px;
				background: #FCE0EA;
				color: var(--primary-color);
				align-items: center;
				text-align: center;
			}

			&--user {
				justify-self: end;
				grid-template-columns: 1fr auto;
			}

			&--assistant {
				justify-self: start;
				grid-template-columns: auto 1fr;
			}

			&_Image {

			}

			&_Message {
				background-color: #fff;
				border-radius: 12px;
				padding: 24px;
			}
		}

		&_Dream {
			display: grid;
			width: auto;
			font-size: 16px;
			margin-bottom: 32px;
			gap:12px;
			background-color: #fff1f1;
			border-radius: 12px;
			padding: 24px;
			white-space: pre-wrap;
		}

		&_Typing {
			margin-bottom: 20px;
		}	
	}

	&_Input {
		padding: 10px;
		position: absolute;
		bottom: 0;
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 8px;
		align-items: center;
		width: 100%;
		background-color: white;
		border-radius: 12px;
		box-shadow: 0px 2px 3px 0px #00000017;
		margin-bottom: 10px;

		.Input {
			padding: 0;
			border-radius: 12px;
			height: auto;
		}
	}

	.TypingDots {
		color: #ffb8d1;
	}
}
