@import '../../../ruya-shared/shared/assets/mixins';

.DreamEdit {
	padding-bottom: 0;

	&_Wrapper {
		display: grid;
		grid-auto-flow: row;
		grid-template-rows: auto auto 1fr;

		.H2 {
			margin: 0;
			margin-bottom: 24px;
		}
	}

	&_Content {

		&_TopBar {
			display: grid;
			grid-auto-flow: column;
			justify-content: space-between;
			width: 100%;
			margin-bottom: 24px;

			div {
				display: grid;
				grid-auto-flow: column;
				gap: 12px;
			}
		}

		&_DreamInfo {
			display: grid;
			grid-auto-flow: column;
			justify-content: start;
			gap: 24px;
			margin-bottom: 24px;
		}
	}

	&_Form {
		display: grid;
		grid-auto-flow: row;
		grid-template-rows: auto 1fr;
		width: 100%;
		min-height: 100%;

		&_Error {
			color: var(--error-color);
		}

		&_Options {
			display: grid;
			grid-auto-flow: column;
			gap: 30px;
			margin-top: 30px;
			justify-content: start;
		}

		&_Buttons {
			display: grid;
			grid-auto-flow: column;
			gap: 10px;
			justify-content: start;
			margin-top: 40px;
			margin-bottom: 20px;
		}
	}

	&_Switch {
		display: grid;
		grid-auto-flow: column;
		gap: 12px;
		justify-content: space-between;
		align-items: center;

		.Switch_Label {
			margin-bottom: 0;
		}
	}

	.FormGroup {
		margin: 0;
	}

	.TextArea {
		height: 100%;
	
		textarea {
			border-radius: 24px 24px 0 0;
			line-height: 25.6px;
			padding: 32px
		}

		&--invalid .TextArea_InvalidMessage {
			background-color: var(--error-color);
			color: #fff;
			min-height: 50px;
			padding: 18px;
			
			.MaterialSymbol {
				color: #fff;
			}
		}
	}
}