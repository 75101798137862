@import '../../../ruya-shared/shared/assets/mixins';

.EventAdd {
	padding-bottom: 0;

	&_Wrapper {
		display: grid;
		grid-auto-flow: row;
		grid-template-rows: auto auto 1fr;

		.H2 {
			margin: 0;
			margin-bottom: 24px;
		}
	}

	&_Content {

		&_TopBar {
			display: grid;
			grid-auto-flow: column;
			justify-content: space-between;
			width: 100%;
			margin-bottom: 24px;

			&_Buttons {
				position: relative;
			}
		}

		&_Date {
			display: grid;
			grid-auto-flow: column;
			justify-content: start;
	
		}

		&_Details {
			display: grid;
			grid-auto-flow: column;
			margin-bottom: 24px;
			justify-content: start;
			gap: 24px;
		}
	}

	&_Form {
		display: grid;
		grid-auto-flow: row;
		grid-template-rows: auto 1fr;
		width: 100%;
		min-height: 100%;

		&--custom {
			grid-template-rows: auto auto 1fr;
		}

		.Input {
			margin-bottom: 24px;
		} 

		&_Error {
			color: var(--error-color);
		}

		&_Buttons {
			display: grid;
			grid-auto-flow: column;
			gap: 10px;
			justify-content: start;
			margin-top: 40px;
			margin-bottom: 20px;
		}
	}

	.FormGroup {
		margin: 0;

		.TextArea {
			height: 100%;
		
			textarea {
				border-radius: 24px 24px 0 0;
				line-height: 25.6px;
				padding: 32px
			}
	
			&--invalid .TextArea_InvalidMessage {
				background-color: var(--error-color);
				color: #fff;
				min-height: 50px;
				padding: 18px;
				
				.MaterialSymbol {
					color: #fff;
				}
			}
		}
	}

	&_Error {
		display: grid;
		grid-template-columns: 24px 1fr;
		gap: 12px;
		position: absolute;
		top: 40px;
		right: 0;
		border-radius: 12px;
		background-color: #FF2525;
		padding: 12px;
		color: #fff;
	}
}