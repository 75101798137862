@import '../../../ruya-shared/shared/assets/mixins';

.InterpreterSelection {
	overflow: auto;

	.Button {
		width: max-content;
	}

	.H2 {
		margin-bottom: 12px;
	}

	&_Explanation {
		margin-bottom: 49px;
	}

	&_List {
		display: grid;
		grid-auto-flow: row;
		gap:12px;
	}

	&_Error {
		color: var(--error-color);
		margin: 0;
	}

}

