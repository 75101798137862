.EventSelector {
	display: grid;
	gap: 8px;
	grid-template-columns: 18px 1fr;
	justify-content: start;
	background-color: transparent;
	cursor: pointer;
	align-items: center;

	font-size: var(--button-sm-font-size);
    border-radius: var(--button-sm-border-radius);
	border-color: var(--button-secondary-border-color);
	border-width: 1px;
	border-style: solid	;
    height: var(--button-sm-height);
	padding: 0 18px;

	&_Select {
		background-color: transparent;
		text-align: start;
		outline: none;
		border: none;
		cursor: pointer;
		color: var(--button-tertiary-color);
		padding: 9px 0;
	}

	// Hover
	&:hover {
		color: var(--button-tertiary-hover-color);
		background-color: var(--button-tertiary-hover-bg);
		border-color: var(--button-tertiary-hover-border-color);

		.EventSelector_Select {
			color: var(--button-tertiary-hover-color);
		}
	}

	&:focus-within {
		color: var(--button-tertiary-focus-color);
		background-color: var(--button-tertiary-focus-bg);
		border-color: var(--button-tertiary-focus-border-color);

		.EventSelector_Select {
			color: var(--button-tertiary-focus-color);
		}
	}

	.MaterialSymbol {
		width: 18px;
		height: 18px;
		font-size: var(--button-sm-icon-size);
	}
}