@import '../../../ruya-shared/shared/assets/mixins';

.DreamView {

	&_Content {

		&_Title {
			margin-bottom: 48px;
		}

		&_DreamInfo {
			display: grid;
			grid-auto-flow: column;
			gap: 15px;
			align-items: center;
			font-size: 14px;
			font-weight: 400;
			justify-content: start;
			margin-bottom: 48px;

			&_Date {
				display: grid;
				grid-auto-flow: column;
				align-items: center;
				gap: 8px;

				.MaterialSymbol {
					font-size: 18px;
					color: #0F3652;
				}
			}

			&_Lucid {
				display: grid;
				grid-auto-flow: column;
				align-items: center;
				gap: 8px;

				.MaterialSymbol {
					font-size: 18px;
					color: #0F3652;
				}
			}

			&_Separator {
				display: block;
				width: 6px;
				height: 6px;
				background-color: #CDCDD6;
				border-radius: 50%;
			}
		}

		&_Dream {
			font-weight: 400;
			font-size: 16px;
			line-height: 25.6px;
			margin-bottom: 48px;
			white-space: pre-wrap;
		}
	}

	&_TopBar {
		display: grid;
		grid-auto-flow: column;
		width: 100%;
		margin-bottom: 48px;
		gap: 12px;

		@include minDesktop {
			justify-content: space-between;
		}

		&_Right {
			display: grid;
			grid-auto-flow: column;
			gap: 12px;
		}

	}

	&_Interpretations {

		&_Analyse {
			display: grid;
			justify-content: space-between;
			align-items: center;
			grid-auto-flow: column;
		}
	}

	&_InterpretationsList {
		display: grid;
		gap: 12px;

		@include minTablet {
			grid-template-columns: repeat(2, 1fr);
		}

		&_Item {
			display: grid;
			grid-template-columns: 34px 1fr min-content;
			gap: 12px;
			cursor: pointer;
			background-color: white;
			border-radius: 12px;
			padding: 12px 16px;
			box-shadow: 0px 2px 3px 0px #00000017;
			align-items: center;

			&_Name {
				font-weight: 400;
				color: #0F3652;
				font-size: 16px;
			}

			&_Icon {
				background: #FCE0EA;
				color: #0F3652;
				width: 34px;
				height: 34px;
				padding: 4px;
				border-radius: 5.33px;
				font-size: 26px;
			}

			&_Arrow {
				border: 1px solid #CDCDD6;
				border-radius: 100px;
				padding: 7px 18px;
				font-size: 18px;
			}

			&:hover {
				.DreamView_InterpretationsList_Item_Name {
					color: var(--highlight-color);
				}

				.DreamView_InterpretationsList_Item_Icon {
					background-color: var(--highlight-color);
					color: #fff;
				}
			}
		}
	}

	&_Actions {
		position: relative;
		text-align: end;

		.DropdownMenu {
			text-align: start;
			right: 0;
			top: 50px;
			min-width: 200px;
		}
	}
}