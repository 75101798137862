.DreamInterpretation {
	padding-bottom: 0;

	&_Wrapper {
		display: grid;
		grid-auto-flow: row;
		grid-template-rows: auto auto 1fr;

		.H2 {
			margin: 24px 0;
		}

		&--error {
			.InfoBox {
				margin-top: 24px;
			}
		}
	}
}
