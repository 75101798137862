.ButtonContinueWith {
	display: grid;
	grid-auto-flow: column;
	gap: 8px;
	white-space: nowrap;
	font-weight: 400;
	align-items: center;
	align-content: center;
	justify-items: center;
	cursor: pointer;
	line-height: 0;
	box-sizing: border-box;

	// Size
	font-size: var(--button-md-font-size);
	height: var(--button-md-height);
	padding: var(--button-md-padding);

	// Border
	border-width: var(--button-border-width);
	border-style: solid;
	border-radius: var(--button-md-border-radius);

	// Colors
	color: var(--button-secondary-color);
	background-color: var(--button-secondary-bg);
	border-color: var(--button-secondary-focus-border-color);

	&.ButtonContinueWith--apple img {
		margin-top: -4px;
	}

	// Hover and Focus
	&:hover,
	&:focus {
		background-color: #fff;

		&.ButtonContinueWith--apple svg {
			path {
				fill: white;
			}
		}

		&.ButtonContinueWith--google svg {
			path {
				fill: #fff;
			}
		}
	}

	&:focus {
		outline: 0;
	}

	span {
		display: grid;
		align-items: center;
		height: 100%;
	}
}