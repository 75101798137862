.Profile {
	max-width: 592px;

	&_Desc {
		margin: 0 0 49px 0;
	}

	&_AccountInfo {
		margin: 0 0 24px 0;
	}

	&_AccountForm {
		margin: 0 0 49px 0;
	}

	&_Wrapper {

		.H2 {
			margin: 0 0 12px 0;
		}

		.Hr {
			margin: 0 0 49px 0;
		}

		&_Info {
			&_Questions {
				padding: 24px;
			}
		}
	}

	.InfoBox {
		margin: 49px 0;
	}
}