@import '../../../ruya-shared/shared/assets/mixins';

// Timeline Dream Box
.TimelineDiary {
	position: relative;
	display: grid;
	background-color: #FDF9FB;
	box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.09);
	border-radius: 8px;
	padding: 24px;
	gap: 12px;

	// Diary Header
	&_Header {
		display: grid;
		grid-auto-flow: column;
		gap: 12px;
		margin-bottom: 24px;

		&_Title {
			display: grid;
			grid-auto-flow: column;
			color: #6f8495;
			justify-content: start;
			align-items: center;
			font-size: 14px;
			font-weight: 400;
			gap: 6px;
		}

		&_Actions {
			display: grid;
			grid-auto-flow: column;
			justify-content: end;
			gap: 12px;
		}
	}

	// Diary Details
	&_Details {
		overflow: hidden;
		position: relative;
		max-height: 180px;
		min-height: 50px;

		&_Content {
			font-size: 16px;
			font-weight: 400;
			line-height: 25.6px;
		}

		&_Gradient {
			position: absolute;
			bottom: 0;
			height: 25px;
			width: 100%;
			background: linear-gradient(0deg, rgba(253, 249, 251, 1) 0%, rgba(253, 249, 251, 0.3) 90%);
		}
	}

	// Diary Actions
	&_Actions {
		position: relative;
	}

	&_Menu {
		position: absolute;
		right: 24px;
		top: 70px;
		background-color: white;
		z-index: 9;
		border: 1px solid black;
		width: 105px;
		height: 73px;
		box-shadow: 0px 2px 3px 0px #00000017;
		border-radius: 12px;
		border: none;
	}

	// Deleted item animation
	&--deleted {
		opacity: 0;
		transition: opacity 0.5s;
	}

}