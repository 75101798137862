@import '../../../ruya-shared/shared/assets/mixins';

.TimelineList {

	&_Day {
		display: grid;		

		@include minTablet {
			grid-template-columns: 60px 1fr;
			gap: 32px;
		}

		&_Date {
			display: grid;
			grid-template-rows: min-content 1fr;
			justify-items: center;

			&_Line {
				width: 1px;
				background-color: #E4E0E6;
				height: 32px;

				@include minTablet {
					height: unset;
				}
			}
		}

		&_Records {
			display: grid;
			grid-auto-flow: row;
			gap: 24px;
			padding-bottom: 24px;
		}
	}
}