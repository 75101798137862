@import '../../../assets/variables';
@import '../../../assets/mixins';

.ContentWrapper {
	width: 100%;
	max-width: 1008px;
	padding: $content-padding-mobile;
	margin: 0 auto;

	&_Content {
		display: grid;
		grid-template-rows: 1fr;
		min-height: 100%;
	}
}
