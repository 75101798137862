.Label {
	font-weight: 400;

	&--md {
		font-size: 14px;
	}

	&--lg {
		font-size: 16px;
	}

	&--sm {
		font-size: 12px;
	}
}