@import '../../../ruya-shared/shared/assets/mixins';

.DreamViewInterpretation {

	&_TopBar {
		display: grid;
		gap: 24px;

		&_Right {
			display: grid;
			grid-auto-flow: column;
			gap: 12px;

			.Button--selected {
				background-color: var(--primary-color) !important;
				color: #fff !important;
				border-color: var(--primary-color) !important;
			}
		}

		@include minTablet {
			display: grid;
			grid-auto-flow: column;
			justify-content: space-between;
			gap: unset;
		}
	}

	&_Content {

		&_Header {
			display: grid;
			grid-template-columns: 48px 1fr;
			gap: 24px;
			align-items: center;
		}

		&_Analysis {
			margin-top: 24px;

			.MaterialSymbol {
				display: grid;
				place-items: center;
				height: 48px;
				width: 48px;
				background: #FCE0EA;
				border-radius: 10.67px;
			}
		}

		&_DreamQA {
			margin-top: 24px;

			.MaterialSymbol {
				display: grid;
				place-items: center;
				height: 48px;
				width: 48px;
				background: #FCE0EA;
				border-radius: 10.67px;
			}

			&_Item {
				display: grid;
				width: auto;
				max-width: 700px;
				font-size: 16px;
				margin-bottom: 32px;
				gap: 12px;

				.MaterialSymbol {
					display: grid;
					width: 36px;
					height: 36px;
					border-radius: 8px;
					font-size: 24px;
					background: #FCE0EA;
					color: var(--primary-color);
					align-items: center;
					text-align: center;
				}

				&--user {
					justify-self: end;
					text-align: right;
					grid-template-columns: 1fr auto;

					.DreamViewInterpretation_Content_DreamQA_Item_Message {
						background-color: transparent;
						padding: 0;
					}
				}

				&--assistant {
					justify-self: start;
					grid-template-columns: auto 1fr;
				}

				&_Message {
					background-color: #fff;
					border-radius: 12px;
					padding: 24px;
				}
			}
		}

		&_Dream {
			margin-top: 24px;

			.MaterialSymbol {
				display: grid;
				place-items: center;
				height: 48px;
				width: 48px;
				background: #FCE0EA;
				border-radius: 10.67px;
			}
		}
	}

	&_Delete {
		display: grid;
		gap: 12px;
		border-radius: 12px;
		padding: 20px;
		border: 1px #CDCDD6 solid;
		margin-top: 24px;

		@include minTablet {
			grid-auto-flow: column;
			justify-content: space-between;
			align-items: center;
		}

		.P, .H4 {
			margin: 0;
		}

		.Button {
			justify-self: end;
		}
	}

	&_Error {
		color: var(--error-color);
	}
}